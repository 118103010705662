.container {
    display: block;
    position: relative;
    /* padding-left: 25px; */
    /* cursor: pointer; */
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    /* cursor: pointer; */
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    /* position: absolute; */
    bottom: 4px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: rgb(232, 229, 229);
    border: 1px rgb(177, 177, 177) solid;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
/* .container:hover input~.checkmark {
    background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #002B4E;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    /* position: absolute; */
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    /* left: 4px; */
    margin-left: 4px;
    top: 0px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(55deg);
    -ms-transform: rotate(55deg);
    transform: rotate(55deg);
}

.filterlabel {
    margin-left: 5px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
}