.footer_size {
  font-size: 12px;
}

.footer_size_sell {
  padding-left: 2vw;
}

.footer_image {
  height: 12px;
  padding-right: 10px;
}

.rotate-container {
  width: 48px; /* Adjust the size of the container */
  height: 48px; /* Adjust the size of the container */
  animation: rotate 3s linear infinite; /* Rotate animation */
  transition: transform 0.3s ease; /* Smooth scaling transition on hover */
  cursor: pointer;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);  */
  border-radius: 50%;
  /* opacity: 50%; */
  opacity: 60%;
}

.rotating-image {
  width: 100%;
  height: 100%;

  /* Make it round */
}

.rotate-container:hover {
  transform: scale(1.2); /* Enlarge on hover */
  transition: transform 0.3s ease;
  animation-play-state: paused; /* Pause the rotation on hover */
  opacity: 80%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating-image.modal-open {
  animation: modal-appear 0.5s ease forwards;
}

@keyframes modal-appear {
  0% {
    transform: scale(0);
    /* opacity: 0; */
  }
  100% {
    transform: scale(1);
    /* opacity: 1; */
  }
}

.message {
  position: fixed;
  font-weight: bold;
  transition: transform 0.5s ease; /* Smooth slide-up transition */
}

.message.slide-up {
  bottom: 120px; /* Final position, same as the rotating image's bottom position */
  animation: slide-up-animation 2s ease; /* Slide-up animation */
}

@keyframes slide-up-animation {
  0% {
    transform: translateY(100%); /* Start from below the viewport */
  }
  100% {
    transform: translateY(0); /* End at the final position */
  }
}

.enquiryMesssage {
  position: relative;

  text-shadow: 0.5px 0.5px 0.5px rgba(225, 225, 225, 0.5); /* Add a subtle shadow */
}

.enquiryCloseBtn {
  display: none;
  position: absolute;
  bottom: 35px;
  right: 0px;
  cursor: pointer;
}

.enquiryMesssage:hover ~ .enquiryCloseBtn {
  display: block;
}

.enquiryCloseBtn:hover {
  display: block;
}

/* animations to expand boxChrome, Safari, Opera */

@-webkit-keyframes openbox {
  0% {
    height: 0px;
    width: 0px;
  }
  100% {
    height: 300px;
    width: 250px;
  }
}

@keyframes openbox {
  0% {
    height: 0px;
    width: 0px;
  }
  100% {
    height: 300px;
    width: 250px;
  }
}

@-webkit-keyframes closebox {
  0% {
    height: 300px;
    width: 250px;
  }
  100% {
    height: 0px;
    width: 0px;
  }
}

@keyframes closebox {
  0% {
    height: 300px;
    width: 250px;
  }
  100% {
    height: 0px;
    width: 0px;
  }
}
/*chat box style */

div.chatBox {
  background: #911;
  z-index: 100;
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px;
  -ms-border-radius: 0px 5px 5px 5px;
  -o-border-radius: 0px 5px 5px 5px;
  border-radius: 0px 5px 5px 5px;
}

/*hides any feature */

.hide {
  display: none;
}

/*executes the hide animation */

.hideBox {
  -webkit-animation: closebox 0.25s ease-out;
  /* Chrome, Safari, Opera */

  animation: closebox 0.25s ease-out;
  height: 0;
  width: 0;
}

/*executes the expand animation*/

.showBox {
  -webkit-animation: openbox 0.25s ease-in;
  /* Chrome, Safari, Opera */

  animation: openbox 0.25s ease-in;
  height: 300px;
  width: 250px;
}
/*chat button style */

a#chat_btn {
  text-decoration: none;
  font-weight: bold;
  background: #911;
  color: #fff;
  letter-spacing: 0.15em;
  padding: 4px 5px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}
/*round corners for chat*/

#chat_btn.round_cor {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

/* next up, add a space to write your chat */
