.item_box {
  background-color: #ffffff;
}
.item_box:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transition-delay: 0.5s;
}

.item_box_image {
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  background-position: center;
  background-size: contain;
}

.item_box:hover .image_transition {
  transform: scale(1.1);
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -ms-transition: transform 0.6s ease-in-out;
}

.companyname {
  text-align: left;
  font-weight: 900;
  letter-spacing: 0px;
  color: rgba(51, 13, 47, 0.5019607843);
  text-transform: uppercase;
  opacity: 1;
}

.reviewCount {
  text-align: left;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ratingbox {
  border-radius: 2px;
  opacity: 1;
  margin: 0;
  padding-right: 2px;
  padding-left: 2px;
  width: auto;
  text-align: right;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.title {
  text-align: left;
  letter-spacing: 0px;
  color: #330d2f;
  text-transform: capitalize;
  opacity: 1;
}

.sellingPrice {
  letter-spacing: 0px;
  color: #050e27;
  text-transform: capitalize;
  opacity: 1;
}

.costPrice {
  text-decoration: line-through;
  letter-spacing: 0px;
  color: rgba(53, 14, 49, 0.8);
  text-transform: capitalize;
  text-decoration-thickness: 1.5px;
}

.discountPercentage {
  letter-spacing: 0px;
  color: #089520;
  opacity: 0.77;
  font-weight: bold;
}

.FPcompanyname {
  text-align: left;
  font-weight: 900;
  letter-spacing: 0px;
  color: rgba(51, 13, 47, 0.5019607843);
  text-transform: uppercase;
  opacity: 1;
}

.FPProductname {
  text-align: left;
  letter-spacing: 0px;
  color: #330d2f;
  text-transform: capitalize;
  opacity: 1;
}

.FPsellingPrice {
  letter-spacing: 0px;
  color: #050e27;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 600;
}

.FPcostPrice {
  text-decoration: line-through;
  text-decoration-thickness: 1.5px;
  letter-spacing: 0px;
  color: rgba(53, 14, 49, 0.8);
  text-transform: capitalize;
}

.FPdiscountPercentage {
  letter-spacing: 0px;
  color: #089520;
  opacity: 0.77;
  font-weight: bold;
}/*# sourceMappingURL=ItemDisplay.css.map */