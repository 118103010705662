.navbar_titles {
  background: #EBE8D1 0% 0% no-repeat padding-box;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.4509803922);
  opacity: 1;
  margin: 0;
}

.titles {
  text-align: center;
  font-weight: 900;
  letter-spacing: 0px;
  color: #27292E;
  text-transform: uppercase;
  font-size: 14px;
  opacity: 1;
}

.signcartmorefont {
  color: var(--black-pearl);
  font-family: var(--font-family-nunito_sans);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.signhover:hover .signfont {
  font-weight: 800;
}

.signhover:hover .signiconfont {
  stroke-width: 0.4px;
}

.carthover:hover .cartfont {
  font-weight: 800;
}

.carthover:hover .carticonfont {
  stroke-width: 0.5px;
}

.morehover:hover .morefont {
  font-weight: 800 !important;
  background-color: none;
}

.morehover:hover .moreiconfont {
  stroke-width: 0.6px;
}/*# sourceMappingURL=NavigationBar.css.map */