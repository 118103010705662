/* layout */



.my_profile_section {
    background-color: transparent;
    flex-direction: column;
    width: 25%;
}


.my_profile {
    background-color: transparent;
    color: var(--cannon-black);
    font-family: var(--font-family-manrope);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    height: 30px;
    left: 139px;
    letter-spacing: 0.00px;
    line-height: 28px;

    text-align: left;
    top: 154px;
    white-space: nowrap;
    width: 120px;
}


.saved-list-6mmUWi {
    background-color: var(--white);
    height: 54px;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    margin-top: 15px;
}

.rectangle-5366-uFa4FX {
    border-radius: 5px;

    height: 48px;

    width: 100%;

}

.saved-list-uFa4FX {
    background-color: transparent;
    bottom: calc(13.64% - 4px);
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    height: 26px;
    margin-left: 2vw;
    letter-spacing: 0.00px;
    line-height: 26px;
    text-align: left;
    white-space: nowrap;
    width: 87px;
}

@media screen and (max-width: 3000px) and (min-width: 1920px) {

    .my_profile {
        font-size: 1.8rem;
    }

    .saved-list-6mmUWi {
        height: 60px !important;
        margin-top: 20px;
    }

    .saved-list-uFa4FX {

        font-size: 1.2rem;
    }

}


@media screen and (max-width: 1000px) and (min-width: 700px) {


    .saved-list-uFa4FX {

        font-size: 0.85rem;
    }

    .my_profile {
        font-size: 18px;
    }

}

/* savedlist */


.cart-items {

    background-color: var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 0px 34px 40px;
    width: 100%;
    min-height: 15vw;
}


.cart-items_1 {

    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000026;
    margin-top: 2vw;
    height: max-content;
    width: auto;
    padding: 20px 15px;
    justify-content: space-between;
}

.overlap-1 {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;


    top: 0;
}



.text-7-1 {
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 1.62px;
    min-height: 21px;
    min-width: 14px;
    white-space: nowrap;
    font-size: 1.1rem;

}

.brake-pad-12 {
    height: 7vw;
    cursor: pointer;
    margin-left: 10px;
    object-fit: cover;
    width: auto;
}

.flex-col-21 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    margin-left: 3vw;
    /* min-height: 142px; */
    width: auto;
}

.brembo-22 {

    letter-spacing: 0;
    width: 100%;
    white-space: nowrap;
    font-size: 1.25rem;
    min-height: 20px;
}

.front-brake-pads-7 {
    letter-spacing: 0;

    min-height: 20px;
    min-width: 140px;
    white-space: nowrap;
    font-size: 1.25rem;
}

.in-stock-9 {
    letter-spacing: 0;

    min-height: 24px;
    white-space: nowrap;
    min-width: 4vw;
    font-size: 1.1rem;
    margin-top: 0.4vw;
}

.out_of_stock {
    letter-spacing: 0;

    min-height: 24px;
    white-space: nowrap;
    min-width: 5vw;
    font-size: 1.1rem;
}

.delivery-by-12 {
    align-items: baseline;
    margin-left: 10px;
    min-width: 13vw;
}

.delivery-by-13 {

    letter-spacing: 0;

    white-space: nowrap;
    font-size: 1.1rem;
}

.x15th-september-8 {

    letter-spacing: 0;

    margin-left: 4px;
    white-space: nowrap;
    font-size: 1.1rem;
}

.dispatch-from-7 {

    margin-left: 10px;
    font-size: 1.1rem;
}

.dispatch-from-8 {

    letter-spacing: 0;

    white-space: nowrap;

}

.place-30 {

    letter-spacing: 0;

    margin-left: 2px;
    white-space: nowrap;

}

.unit-price-6 {
    margin-top: 1.5vw;
    font-size: 1.1rem;
}

.unit-price-7 {

    letter-spacing: 0;

    white-space: nowrap;

}

.rs-144900-3 {

    letter-spacing: 0;

    margin-left: 10px;
    white-space: nowrap;

}

.quantity-14 {


    margin-top: 4px;
    align-items: end;
}

.quantity-15 {

    letter-spacing: 0;
    align-items: flex-end;
    white-space: nowrap;
    font-size: 1.25rem;
}

.overlap-group-61 {
    align-items: center;
    background-color: var(--white);
    /* box-shadow: 0px 0px 2px #00000040; */
    display: flex;

    justify-content: flex-end;
    margin-left: 6px;

    padding: 0 5.8px;
}

.savedlistSelectbox {}

.add_to_cart {
    height: 2rem !important;
    /* width: 82px !important; */
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 0px 2px #00000040;
    font-size: 1.1rem !important;
    margin-left: 20px;
}



.flex-col-22 {
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2.14px;
}



.product-1-price-6 {

    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 10px;

}

.x30-off-15 {
    margin-top: 2px;
    letter-spacing: 0;

    text-align: right;

    white-space: nowrap;
    font-size: 1.1rem;

}

.text-7-2 {
    letter-spacing: 0;

    text-align: right;
    text-decoration: line-through;
    white-space: nowrap;
    font-size: 1.1rem;
}


.rs-191800-11 {
    letter-spacing: 0;
    text-align: right;
    white-space: nowrap;
    font-size: 1.25rem;
}

.move-to-cart-N1E04U {
    background-color: transparent;
    height: 15vw;
    max-height: 215px;
    left: calc(47.00%);
    position: absolute;
    top: 30%;
    width: 35vw;
    max-width: 500px;
    box-shadow: 0px 0px 10px #00000059;
    background-color: var(--white-rock-2);
    border-radius: 5px;
}




.item-moved-to-cart-qOY6ju {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;

    left: 13vw;
    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: left;
    top: 20%;
    white-space: nowrap;
    width: 100%;
}


.okaycancel {
    background-color: transparent;
    position: absolute;
    right: 10%;
    bottom: calc(27.64% - 8px);
    height: 2.5vw;
}



.rectangle-5731-MGcnFb {
    background-color: var(--white);
    border-radius: 2px;
    box-shadow: 0px 3px 6px #00000029;
    width: 4vw;
    margin-right: 15px;

}

.close-MGcnFb {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    left: 6px;
    letter-spacing: 0.00px;

    text-align: center;
    white-space: nowrap;
}


.rectangle-5732-cbIu4D {
    background-color: var(--royal-blue);
    border-radius: 2px;
    box-shadow: 0px 3px 6px #330d2f29;
    width: 5vw;
}


.view-cart-cbIu4D {
    background-color: transparent;
    color: var(--white);
    font-family: var(--font-family-nunito_sans);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;

    letter-spacing: 0.00px;

    text-align: center;

    white-space: nowrap;

}

.group-32692-qOY6ju {
    background-color: transparent;
    height: 13px;
    position: absolute;
    right: 13px;
    top: 4px;

}


.rectangle-5762-qOY6ju {
    background-color: transparent;
    height: 7vw;
    left: 7%;
    overflow: hidden;
    position: absolute;
    top: calc(36.92% - 32px);
    width: auto;
}


.are-you-sure {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;

    left: 2vw;
    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: left;
    top: 20%;
    white-space: nowrap;
    width: 100%;
}


@media screen and (max-width: 1300px) and (min-width: 1000px) {


    .text-7-1 {

        font-size: 0.8rem !important;
    }

    .brembo-22 {
        font-size: 0.9rem !important;
    }

    .front-brake-pads-7 {

        font-size: 0.9rem;
    }


    .in-stock-9 {

        font-size: 0.8rem;
    }

    .out_of_stock {
        font-size: 0.8rem;
    }


    .delivery-by-13 {
        font-size: 0.8rem;
    }

    .x15th-september-8 {
        font-size: 0.8rem;
    }

    .dispatch-from-7 {


        font-size: 0.8rem;
    }

    .unit-price-6 {

        font-size: 0.8rem;
    }

    .quantity-15 {
        font-size: 0.9rem;
    }


    .savedlistSelectbox {
        font-size: 0.8rem !important;
        height: 1.5rem !important;
        padding-left: 10px !important;
        padding-right: 20px !important;

    }

    .chakra-select__icon-wrapper {

        right: 0 !important;
    }

    .add_to_cart {
        font-size: 0.8rem !important;
        height: 1.5rem !important;
        width: 90px !important;
    }

    .x30-off-15 {
        font-size: 0.8rem;

    }

    .text-7-2 {

        font-size: 0.8rem;
    }

    .rs-191800-11 {
        font-size: 0.9rem;
    }

    .item-moved-to-cart-qOY6ju {
        font-size: 1rem;

    }

    .close-MGcnFb {
        font-size: 0.6rem;
    }

    .view-cart-cbIu4D {
        font-size: 0.6rem;
    }

    .are-you-sure {
        font-size: 1rem;
    }

}

@media screen and (max-width: 999px) and (min-width: 900px) {


    .text-7-1 {

        font-size: 0.7rem !important;
    }

    .brembo-22 {
        font-size: 0.8rem !important;
    }

    .front-brake-pads-7 {

        font-size: 0.8rem;
    }


    .in-stock-9 {

        font-size: 0.65rem;
    }

    .out_of_stock {
        font-size: 0.65rem;
    }


    .delivery-by-13 {
        font-size: 0.65rem;
    }

    .x15th-september-8 {
        font-size: 0.65rem;
    }

    .dispatch-from-7 {


        font-size: 0.65rem;
    }

    .unit-price-6 {

        font-size: 0.65rem;
    }

    .quantity-15 {
        font-size: 0.75rem;
    }

    .savedlistSelectbox {
        font-size: 0.7rem !important;
        height: 1.5rem !important;
        padding-left: 5px !important;
        padding-right: 20px !important;

    }

    .chakra-select__icon-wrapper {
        width: auto !important;
        height: 65% !important;
        top: 55% !important;
        right: 0 !important;
    }

    .add_to_cart {
        font-size: 0.7rem !important;
        height: 1.5rem !important;
        width: 80px !important;
    }

    .x30-off-15 {
        font-size: 0.7rem;

    }

    .text-7-2 {

        font-size: 0.7rem;
    }

    .rs-191800-11 {
        font-size: 1rem;
    }

    .item-moved-to-cart-qOY6ju {
        font-size: 1.1rem;

    }

    .close-MGcnFb {
        font-size: 0.7rem;
    }

    .view-cart-cbIu4D {
        font-size: 0.7rem;
    }

    .are-you-sure {
        font-size: 1.1rem;
    }

}

@media screen and (max-width: 899px) and (min-width: 700px) {


    .text-7-1 {

        font-size: 0.6rem !important;
    }

    .brembo-22 {
        font-size: 0.7rem !important;
    }

    .front-brake-pads-7 {

        font-size: 0.7rem;
    }


    .in-stock-9 {

        font-size: 0.7rem;
    }

    .out_of_stock {
        font-size: 0.55rem;
    }


    .delivery-by-13 {
        font-size: 0.55rem;
    }

    .x15th-september-8 {
        font-size: 0.55rem;
    }

    .dispatch-from-7 {


        font-size: 0.55rem;
    }

    .unit-price-6 {

        font-size: 0.55rem;
    }

    .quantity-15 {
        font-size: 0.7rem;
    }

    .savedlistSelectbox {
        font-size: 0.7rem !important;
        height: 1.2rem !important;
        padding-left: 5px !important;
        padding-right: 15px !important;

    }

    .chakra-select__icon-wrapper {
        width: auto !important;
        height: 65% !important;
        top: 60% !important;
        right: 0 !important;
    }

    .add_to_cart {
        font-size: 0.7rem !important;
        height: 1.2rem !important;
        width: 70px !important;
    }

    .x30-off-15 {
        font-size: 0.6rem;

    }

    .text-7-2 {

        font-size: 0.6rem;
    }

    .rs-191800-11 {
        font-size: 0.9rem;
    }

    .item-moved-to-cart-qOY6ju {
        font-size: 1rem;

    }

    .close-MGcnFb {
        font-size: 0.6rem;
    }

    .view-cart-cbIu4D {
        font-size: 0.6rem;
    }

    .are-you-sure {
        font-size: 1rem;
    }

}

/* Gift&Coupons */

.rectangle-json {
    background-color: transparent;
    max-height: 400px;
    height: 20vw;
    left: calc(49.93% - 211px);
    overflow: hidden;

    top: 0px;
    width: auto;
}

.we-do-not-have-gifting-options-right-now-gX6Hov {
    background-color: transparent;

    margin-top: 5vw;
    margin-bottom: 5vw;
    letter-spacing: 0.00px;
    font-size: 1.2rem;

    text-align: center;
    white-space: nowrap;

}

.if-you-have-a-promoc-YLfheh {
    background-color: transparent;
    color: var(--mountain-mist);
    font-family: var(--font-family-nunito_sans);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;

    letter-spacing: 0.00px;

    right: 0;
    text-align: right;
    margin-top: 20px;
    white-space: nowrap;

}

@media screen and (max-width: 1300px) and (min-width: 700px) {


    .we-do-not-have-gifting-options-right-now-gX6Hov {
        font-size: 1rem;
    }

}

/* my garage */

.rectangle-6045-qYBBoK {
    background-color: transparent;
    height: calc(100.00% - 77px);
    left: calc(48.82% - 156px);
    overflow: hidden;

    top: 19px;
    width: 320px;
}

.my-garage-will-be-rolled-out-soon-qYBBoK {
    background-color: transparent;

    height: 26px;
    letter-spacing: 0.00px;
    font-size: 1.2rem;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}


@media screen and (max-width: 1300px) and (min-width: 700px) {


    .my-garage-will-be-rolled-out-soon-qYBBoK {
        font-size: 1rem;
    }

}

/* address */



.address {
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000026;
    margin-top: 40px;
    height: max-content;
    min-height: 140px;
    width: auto;
    padding: 12.4px 14px;
    justify-content: space-between;
}

.lottie_address {
    background-color: transparent;
    height: 4vw;
    width: 4vw;

}

.lottie_name {
    background-color: var(--sizzling-sunrise);
    border-radius: 10px;
    padding: 3px;
    width: 4vw;
    min-width: 40px;
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

.home-address {
    height: max-content;
    font-size: 1rem;
}


.add_address {
    background-color: var(--turbo) !important;
    border-radius: 5px !important;
    color: var(--melanzane);
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.00px;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    width: 145px;
}


.addressbox {
    background-color: var(--white-rock-2);
    border-radius: 5px;
    box-shadow: 0px 0px 10px #00000059;
    width: 40vw !important;
    min-width: 500px;
}

.add_edit_adress {

    color: var(--black-pearl);
    font-family: var(--font-family-manrope);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.5vw;
}

.lottie_address_modal {
    background-color: transparent;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: -3vw;

    width: 20vw;
    min-width: 240px;
    max-width: 300px;
}


.inputbox {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem;
    margin-top: 1rem !important;
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;

}

.inputbox3 {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem;
    /* margin-top: 1rem ; */
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;

}

.place_element {

    color: #ACACAC;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
}

.inputbox2 {
    background-color: var(--white) !important;
    border: none !important;
    border-radius: 2px !important;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem !important;
    margin-top: 1rem;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    min-width: 165px !important;


}

.input91 {
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.cancel {
    background-color: transparent !important;
    border-radius: 5px !important;
    width: 5vw;
    max-width: 85px;
    min-width: 50px;
    letter-spacing: 0.00px;
    text-align: center;
    white-space: nowrap;
    font-size: 1rem !important;
    border: 1px solid var(--melanzane);
}

.radio_buttons {


    border-color: #f9e107;
    height: 1.5rem !important;
    width: 1.5rem !important;
}

.css-10j71as[aria-checked=true], .css-10j71as[data-checked] {

    background: var(--turbo) !important;
    border-color: var(--turbo) !important;
    color: var(--chakra-colors-white);
}

.css-1q7jgol[aria-checked=true], .css-1q7jgol[data-checked] {
    background: var(--turbo) !important;
    border-color: var(--turbo) !important;
    color: var(--chakra-colors-white);
}

.css-1q7jgol[aria-checked=true], .css-1or39uu[data-checked] {
    background: var(--turbo) !important;
    border-color: var(--turbo) !important;
    color: var(--chakra-colors-white);

}

.radiobox {
    font-size: 11px;
}

.save_proceed {
    background-color: var(--royal-blue) !important;
    color: var(--white);
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    border-radius: 5px !important;

    min-width: 165px;
    max-width: 220px;
    width: 17vw;
}

.save_proceed_mob {
    font-family: var(--font-family-nunito_sans);
    font-size: 0.8rem !important;
    font-style: normal;
    font-weight: 400;

    width: 200px !important;
}

@media screen and (max-width: 427px) {

    .lottie_address {
        width: 45px;
        height: 45px;
    }

    .lottie_name {
        font-size: 10px;
        border-radius: 7px;
        padding: 1px;
    }

    .home-address {
        font-size: 0.8rem;
    }

    .add_address {
        font-size: 0.8rem !important;
    }

    .inputbox {
        font-size: 0.8rem !important;
    }

    .place_element {
        font-size: 0.8rem !important;
    }

    .inputbox3 {
        font-size: 0.8rem !important;

    }

    .inputbox2 {
        font-size: 0.8rem !important;

    }

    .input91 {
        font-size: 0.8rem;

    }

    .radiobox {
        font-size: 9px;
    }



}


@media screen and (max-width: 799px) and (min-width: 428px) {

    .lottie_address {
        width: 45px;
        height: 45px;
    }

    .lottie_name {
        font-size: 10px;
        border-radius: 7px;
        padding: 1px;
    }

    .home-address {
        font-size: 0.8rem;
    }

    .add_address {
        font-size: 0.8rem !important;
    }

    .inputbox {
        font-size: 0.8rem !important;
    }

    .place_element {
        font-size: 0.8rem !important;
    }

    .inputbox3 {
        font-size: 0.8rem !important;

    }

    .inputbox2 {
        font-size: 0.8rem !important;

    }

    .input91 {
        font-size: 0.8rem;

    }

    .radiobox {
        font-size: 9px;
    }




}




@media screen and (max-width: 1300px) and (min-width: 800px) {


    .lottie_name {
        font-size: 6px;
        border-radius: 7px;
    }

    .home-address {
        font-size: 0.8rem;
    }

    .add_address {
        font-size: 0.8rem !important;
    }

    .inputbox {
        font-size: 0.8rem !important;
    }

    .place_element {
        font-size: 0.8rem !important;
    }

    .inputbox2 {
        font-size: 0.8rem !important;

    }

    .input91 {
        font-size: 0.8rem;

    }

    .radiobox {
        font-size: 9px;
    }



}


@media screen and (min-width: 1600px) {

    .address {
        min-height: 170px;
    }

    .lottie_name {
        font-size: 10px;

    }

    .home-address {
        font-size: 1.2rem;
    }

    .add_address {
        font-size: 1.2rem !important;
    }

    .my {
        font-size: 1rem;
    }


}



/* notifications */

.notifications {

    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000026;
    margin-top: 40px;
    max-height: max-content;
    min-height: 140px;
    width: auto;
    padding: 12.4px 14px;

}

.place-lottie-here-o8tpQI {
    background-color: transparent;
    height: auto;

    overflow: hidden;

    width: 3vw;

}

.contrary-to-popular-o8tpQI {

    background-color: transparent;
    letter-spacing: 0.00px;
    text-align: left;
    width: 80%;
    margin-right: 10%;
    font-size: 1rem;
}

.markCross {
    justify-content: end;

    margin-right: 5px;
}

.mark-as-read-o8tpQI {

    background-color: transparent;

    letter-spacing: 0.00px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    font-size: 0.8rem;

}

.delete-this-notification {
    background-color: transparent;

    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: right;
    top: 25%;
    white-space: nowrap;
    width: 30vw;
    max-width: 450px;
    font-size: 1rem;

}

.item-moved-to-cart-qOY6ju {
    background-color: transparent;
    color: var(--shark);
    font-family: var(--font-family-nunito_sans);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;

    left: 13vw;
    letter-spacing: 0.00px;
    line-height: 18px;
    position: absolute;
    text-align: left;
    top: 20%;
    white-space: nowrap;
    width: 100%;
}


.canceldelete {
    background-color: transparent;
    position: absolute;
    right: 10%;
    bottom: calc(27.64% - 8px);
    width: 12vw;
    max-width: 170px;
    min-width: 105px;
    max-height: 30px;
    height: 2vw;
    justify-content: space-between;
    font-size: 0.7rem;
}

.rectangle-5291-6C7pzf {
    background-color: transparent;
    border-radius: 5px;
    height: 100%;
    width: 5vw;
    max-width: 70px;
    min-width: 50px;

}

.cancel-6C7pzf {
    background-color: transparent;
    letter-spacing: 0.00px;
    text-align: center;
    white-space: nowrap;
    font-size: 0.7rem;
}

.rectangle-5290-xcKw32 {
    background-color: var(--royal-blue);
    border-radius: 5px;
    width: 5vw;
    max-width: 70px;
    min-width: 50px;
}

.delete-xcKw32 {
    background-color: transparent;
    letter-spacing: 0.00px;
    text-align: center;

    white-space: nowrap;

}

.lottiedelete {

    background-color: transparent;
    height: 8vw;
    max-height: 115px;
    left: 10%;
    overflow: hidden;
    position: absolute;
    top: 22%;
    width: auto;
}

@media screen and (max-width: 1300px) and (min-width: 700px) {


    .contrary-to-popular-o8tpQI {
        font-size: 0.8rem;
    }

    .mark-as-read-o8tpQI {

        font-size: 0.6rem;
    }

    .delete-this-notification {
        font-size: 0.8rem;

    }

    .cancel-6C7pzf {

        font-size: 0.6rem;

    }

    .delete-xcKw32 {

        font-size: 0.6rem;

    }
}

/* order history */

.order-items {

    background-color: var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    width: 100%;
}

.order_header {
    padding: 20px;
    box-shadow: 0px 1px 3px #00000029;
}


.headerdisplaytab {
    display: none !important;
}

.order_headings {
    font-size: 12px;
}


.order_head_sub {
    font-size: 12px;
}

.order_head_sub_time {
    font-size: 10px;

}

.orderdata {
    padding: 30px 20px 50px;
}

.order_image {
    width: 80px;
}

.order_brand {
    font-size: 16px;
}
.orderredirect{
    height: 83px;
}

.order_product {
    font-size: 16px;
}

.order_product_data {
    font-size: 13px;
    margin-top: 5px;
}

.order_quantity_dis {
    font-size: 12px;
}

.order_dotted_text {
    padding: 6px;
    font-size: 11px;
}

.order_button_big {

    color: var(--haiti);
    font-family: var(--font-family-nunito_sans);
    font-weight: 400 !important;
}

.order_button_med {
    color: var(--haiti);
    font-family: var(--font-family-nunito_sans);
    font-weight: 400 !important;

}

.order_button_sml {
    color: var(--haiti);
    font-family: var(--font-family-nunito_sans);
    font-weight: 400 !important;

}

.order_divider {
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
}

.order_bot_btn {
    margin-right: 10vw;
    margin-top: 10px;
}

.order_button_track {

    height: 1.5rem !important;
    font-size: 10px !important;
    font-weight: 400 !important;

}


@media screen and (max-width: 1024px) and (min-width: 800px) {

    .order_header {
        padding: 20px;
    }

    .order_headings {
        font-size: 11px;
    }

    .headerdisplayweb {
        display: none !important;
    }

    .headerdisplaytab {
        display: flex !important;
    }


    .order_head_sub {
        font-size: 11px;
        margin-left: 20px;
    }

    .order_head_sub_time {
        font-size: 9px;
    }

    .orderdata {
        padding: 30px 20px 50px;
    }

    .order_image {
        width: 80px;
    }

    .order_brand {
        font-size: 14px;
    }

    .order_product {
        font-size: 14px;
    }

    .order_product_data {
        font-size: 13px;
    }

    .dispatchorder {
        margin-top: 10px;
    }

    .order_quantity_dis {
        font-size: 12px;
        flex-direction: column;
    }

    .order_dotted_text {
        padding: 6px;
        font-size: 10px;
    }

    .order_button_big {


        padding: 5px 15px !important;
        height: 1.7rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;
    }

    .order_button_med {

        padding: 10px 20px !important;
        height: 1.7rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;

    }

    .order_button_sml {

        height: 1.7rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;

    }

    .order_bot_btn {
        margin-right: 10vw;
    }

}

@media screen and (max-width: 1366px) and (min-width: 1025px) {

    .order_header {
        padding: 20px;
    }

    .order_headings {
        font-size: 12px;
    }

    .order_head_sub {
        font-size: 12px;
    }

    .order_head_sub_time {
        font-size: 10px;
    }

    .orderdata {
        padding: 30px 20px 50px;
    }

    .order_image {
        width: 80px;
    }

    .order_brand {
        font-size: 16px;
    }

    .order_product {
        font-size: 16px;
    }

    .order_product_data {
        font-size: 13px;
    }

    .order_quantity_dis {
        font-size: 12px;
    }

    .order_dotted_text {
        padding: 6px;
        font-size: 11px;
    }

    .order_button_big {


        padding: 10px 30px !important;
        height: 2rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;
    }

    .order_button_med {

        padding: 10px 20px !important;
        height: 2rem !important;
        font-size: 11px !important;
        font-weight: 400 !important;

    }

    .order_button_sml {

        height: 2rem !important;
        font-size: 12px !important;
        font-weight: 400 !important;

    }

    .order_bot_btn {
        margin-right: 10vw;
    }

}

@media screen and (max-width: 1920px) and (min-width: 1367px) {


    .order_header {
        padding: 20px;
    }

    .order_headings {
        font-size: 1rem;
    }

    .order_head_sub {
        font-size: 1rem;
    }

    .order_head_sub_time {
        font-size: 0.8rem;
    }

    .orderdata {
        padding: 40px 30px 60px;
    }

    .order_image {
        width: 100px;
        cursor: pointer;
    }

    .order_brand {
        font-size: 1.2rem;
    }

    .order_product {
        font-size: 1.2rem;
    }

    .order_product_data {
        font-size: 1rem;
    }

    .order_quantity_dis {
        font-size: 0.9rem;
    }

    .order_dotted_text {
        padding: 10px;
        font-size: 1rem;
    }

    .order_button_big {


        padding: 10px 40px !important;
        font-size: 1.1rem !important;
        font-weight: 400 !important;
    }

    .order_button_med {

        padding: 10px 30px !important;
        font-size: 1.1rem !important;
        font-weight: 400 !important;

    }

    .order_button_sml {
        font-size: 1.1rem !important;
        font-weight: 400 !important;

    }

    .order_bot_btn {
        margin-right: 11vw;
    }

    .order_button_track {

        height: 2rem !important;
        font-size: 14px !important;
    
    }

}

@media screen and (max-width: 3000px) and (min-width: 1921px) {


    .order_header {
        padding: 20px;
    }

    .order_headings {
        font-size: 1.2rem;
    }

    .order_head_sub {
        font-size: 1.2rem;
    }

    .order_head_sub_time {
        font-size: 1rem;
    }

    .orderdata {
        padding: 60px 50px 80px;
    }

    .order_image {
        width: 100px;
    }

    .order_brand {
        font-size: 1.4rem;
    }

    .order_product {
        font-size: 1.4rem;
    }

    .order_product_data {
        font-size: 1.2rem;
    }

    .order_quantity_dis {
        font-size: 1.1rem;
    }

    .order_dotted_text {
        padding: 10px;
        font-size: 1.2rem;
    }

    .order_button_big {


        padding: 10px 40px !important;
        font-size: 1.3rem !important;
        font-weight: 400 !important;
    }

    .order_button_med {

        padding: 10px 30px !important;
        font-size: 1.3rem !important;
        font-weight: 400 !important;

    }

    .order_button_sml {
        font-size: 1.3rem !important;
        font-weight: 400 !important;

    }

    .order_bot_btn {
        margin-right: 11vw;
    }

    .order_button_track {
        height: 2rem !important;
        font-size: 14px !important;
    
    }

}

/* PROFILE */

.profile {
    background-color: var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 40px 100px 100px;
    width: 100%;
}

.main_box_profile {
    padding: 40px 30px 80px 80px;
}

.heading_profile {
    font-size: 1.4rem;
}

.databox_profile {
    margin-top: 100px;
}

.inputoutput {
    font-size: 1rem;

}

.inputoutputbox {
    font-size: 1rem;
    width: 100%;

}

.inputoutput_text {
    margin-top: 40px;
    height: 24px;
}

.inputbox_profile {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem;
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 70%;
    margin-top: 33px;

}

.button_edit_save {
    font-family: var(--font-family-nunito_sans) !important;
    border-radius: 5px !important;
    font-style: normal !important;
    box-shadow: 0px 2px 6px #00000029 !important;
    font-weight: 700 !important;
    font-size: 1rem !important;
}

.review_heading_profile {
    font-size: 1.2rem;
    margin-left: 20px;
}

.feature_rolled_out {
    font-size: 1rem;
}

.review_box {
    margin-top: 30%;
    margin-bottom: 30%;
}

.are_you_sure {
    font-size: 1.2rem;
}

.alertbuttonfont {
    font-size: 0.9rem;
}


/* change password */


.pass_heading {
    font-size: 1rem;
}

.inputbox_change_pass_profile {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #00000033;
    height: 2rem;
    padding-left: 10px;
    padding-right: 5px;
    font-family: var(--font-family-nunito_sans);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;

}

.passfont {
    font-size: 1rem;
}

.confirm_btn {
    font-size: 1rem !important;
}

/* PASSWORD CHANGED  */
/* PASSWORD ERROR */

.password_lottie {
    height: 125px;
    width: 125px;
    margin-bottom: 80px;
}

.password_headng {
    font-size: 1rem;
}

.password_error_lottie {
    height: 180px;
    width: 220px;

}

/* DEACTIVATE 1 */

.deactivate_heading {
    font-size: 1rem;

}

.confirm_btn1 {
    font-size: 0.9rem !important;
    font-weight: 800 !important;
    height: 2rem !important;
}

.deactivate1_lottie {
    height: 200px;
    width: 125px;
    margin-top: 40px;
    margin-bottom: 55px;
}

/* DEACTIVATE 2 */

.deactivate2_lottie {
    height: 300px;
    width: 300px;
    margin-bottom: 1px;
}

.deactivate_heading2 {
    font-size: 0.9rem;
    white-space: nowrap;
    text-transform: uppercase;

}

.resend_deactivate {
    font-size: 1rem;
}

.timer_deactivate {
    font-size: 0.8rem;
    margin-left: 100px;
    margin-top: -20px;
}

/* DEACTIVATE FINAL */

.deactivate3_lottie {
    width: 300px;
}

.deactivate3_text_section {
    margin-top: 225px;
}

.it_takes {
    font-size: 1.5rem;

}

.we_can {
    font-size: 0.8rem;
}

.your_accnt {
    font-size: 0.8rem;
}

@media screen and (max-width: 3000px) and (min-width: 1920px) {

    .profile {
        padding: 50px 120px 100px;
    }

    .main_box_profile {
        padding: 60px 50px 100px 100px;
    }

    .heading_profile {
        font-size: 1.4rem;
    }

    .inputoutput {
        font-size: 1.2rem;
        /* width: 60%; */
    }

    .inputbox_profile {
        height: 2.5rem;
        margin-top: 26px;
        width: 70%;
        font-size: 1.2rem;


    }

    .button_edit_save {

        font-size: 1.2rem !important;
    }

    .btn3 {
        font-size: 1.3rem !important;
    }

    .review_heading_profile {
        font-size: 1.6rem;
        margin-left: 30px;
    }

    .feature_rolled_out {
        font-size: 1.2rem;
    }


}




@media screen and (max-width: 1365px) and (min-width: 1280px) {

    .profile {

        padding: 40px 80px 80px;

    }

    .heading_profile {
        font-size: 1.2rem;
    }

    .review_heading_profile {
        font-size: 1.2rem;
    }


}

@media screen and (max-width: 1279px) and (min-width: 1024px) {

    .profile {

        padding: 40px 80px 40px;

    }

    .main_box_profile {
        padding: 40px 30px 50px 50px;
    }

    .heading_profile {
        font-size: 1.1rem;

    }

    .button_edit_save {
        font-size: 0.9rem !important;
        height: 2rem !important;
    }

    .btn3 {
        font-size: 0.9rem !important;
        height: 2rem !important;
    }

    .feature_rolled_out {
        font-size: 0.8rem;
    }

    .review_heading_profile {
        font-size: 1.1rem;
    }

    .review_box {
        margin-top: 25%;
        margin-bottom: 20%;
    }

}

@media screen and (max-width: 1023px) and (min-width: 800px) {

    .profile {

        padding: 40px 40px 40px;

    }

    .main_box_profile {
        padding: 30px 10px 30px 30px;
    }

    .heading_profile {
        font-size: 1rem;
    }

    .review_heading_profile {
        font-size: 1rem;
    }

    .inputoutput_text {
        font-size: 0.9rem;
    }

    .inputoutput_text {
        margin-top: 20px;
    }

    .inputbox_profile {
        font-size: 1rem;
        margin-top: 33px;

    }

    .button_edit_save {
        font-size: 0.8rem !important;
        height: 1.8rem !important;
    }

    .btn3 {
        font-size: 0.8rem !important;
        height: 1.8rem !important;
    }

    .feature_rolled_out {
        font-size: 0.6rem;
    }

    .review_box {
        margin-top: 20%;
        margin-bottom: 15%;
    }



}